import React, { useState, useEffect } from 'react';
import Header from '../../component/dashboard/header';
import 'antd-mobile/dist/antd-mobile.css';
import {Row, Col,} from 'antd';
import { useHistory } from 'react-router-dom'
import { useDispatch,useSelector } from 'react-redux';
import Dash from './dashboard';
import Lib from '../library/library';
import Down from '../library/downloaded'
import './dashboardStyles.css';
import { sethometab } from '../../redux/actions/dashboardActions';
import Space from '../../component/dashboard/space';
import { HomeOutlined,DownloadOutlined,ProfileOutlined} from '@ant-design/icons';
import {logo} from '../../api/api'


const Home = () => {
    const history = useHistory();
    const [loading, setloading] = useState(true);
    const dispatch = useDispatch();
    const state = useSelector((state) => state.App);
     
    useEffect(() => {
        const userCredentials = JSON.parse(localStorage.getItem('userCredentials'));
        if (userCredentials != null) {
            if (userCredentials.emailVerified != 1) {
                history.push('./login');
                setloading(false)
            }
        }
        else {
            history.push('./login');
            setloading(false)
        }
    }, [])
 const callback=(key)=> {
    console.log(key);
  }
  const sethome = () => {
     dispatch(sethometab("home"))

  
}
const setlib = () => {
     dispatch(sethometab("lib"))

  
}
const setdow = () => {
     dispatch(sethometab("dow"))

   
}
   
   
    
   
    return (
        <div style={{background:'#ffffff'}}>
        <Row style={{ "padding": "0px", "margin": "0px 0px 0px 0px" }}>
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>
                 <Header /> 
                 <Space hig="1px" color="#ECF0EF"/>

               {state.hometab=="home"?
                        <Dash/>
                        : null}
                         {state.hometab=="lib"?
                        <Lib/>
                        : null}
                          {state.hometab=="dow"?
                        <Down/>
                        : null}
               
            </Col>
        </Row>
        {/* <div className="fooder">
        <div className="navlogo">
          <img src={logo} alt="" style={{ width: '100%', height: '100%' }} />
        </div>
            <div className="fooder_continer">
         
                <div>  Privacy & Cookie Policy | Powered by: Nova</div>
                <div>© 2021 Newgen KnowledgeWorks</div>
            </div>
        </div> */}
        <div className="tabbarrcontiner">
        <div className="tabbarr" >
                    <div className="tabbar_itemm" onClick={() => sethome()} style={{  color: state.hometab=="home" ? "#ffffff" : "#515150" }}><HomeOutlined style={{fontSize:"15px"}}/>Home </div>
                    <div className="tabbar_itemm" onClick={() => setlib()} style={{  color: state.hometab=="lib" ? "#ffffff" : "#515150" }}><ProfileOutlined style={{fontSize:"15px"}} />Library</div>
                    <div className="tabbar_itemm" onClick={() => setdow()} style={{ color: state.hometab=="dow" ? "#ffffff" : "#515150" }}><DownloadOutlined style={{fontSize:"15px"}} />Downloaded</div>
                </div>
                </div>
    </div>


    )
}

export default Home;