export const ActionType={
    SET_SUBJECT:"SET_SUBJECT",
    SET_NEWBOOKS:"SET_NEWBOOKS",
    SET_BESTRATINGBOOKS:"SET_BESTRATINGBOOKS",
    SET_OPENACCESSGBOOKS:"SET_OPENACCESSGBOOKS",
    SET_NEWJOURNALS:"SET_NEWJOURNALS",
    SET_BESTRATINGJOURNALS:"BESTRATINGJOURNALS",
    SET_SELECTEDITEM:"SET_SELECTEDITEM",
    SET_OFFLINEDATA:"SET_OFFLINEDATA",
    SET_SUBSCRIPTION:"SET_SUBSCRIPTION",
    SET_SUBSCRIPTIONFETCH:"SET_SUBSCRIPTIONFETCH",
    SET_THEME:"SET_THEME",
    SET_BG:"SET_BG",
    SET_TAB:"SET_TAB",
    SET_HOMETAB:"SET_HOMETAB",
    SET_RESET:"SET_RESET",
    SET_SEARCHBOOKS:'SET_SEARCHBOOKS',
    SET_ISTOCOPEN:'SET_ISTOCOPEN',
    SET_EPUBLOCATION:'SET_EPUBLOCATION'

}