import React,{useState,useEffect} from "react";
import rateimg from '../../api/images/rated.png'
import { Button,Rate,Input,message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useIndexedDB } from 'react-indexed-db';
import { useDispatch } from 'react-redux';
import { rateus } from '../../api/api'
import './styles.css';
const { TextArea } = Input;

const RateCard = () => {
  const [feedback,setfeedback]=useState('hit')
  const [rating,setrating]=useState('')
  const [app,setapp]=useState('mobile application')
  const [version,setversion]=useState('1')
  const [isloading,setisloading]=useState(false)
  const userCredentials = JSON.parse(localStorage.getItem('userCredentials'));
  
  useEffect(()=>{
    const oldrating = JSON.parse(localStorage.getItem('rating'));
    const oldfeedback = JSON.parse(localStorage.getItem('feedback'));
     setrating(oldrating)
     setfeedback(oldfeedback)
  },[])

 const  handleChange = value => {
  setrating(value)
};

const onChange = e => {
  setfeedback(e.target.value)
  console.log('Change:', e.target.value);
};

const rateUs=()=>{
  var myHeaders = new Headers();
  myHeaders.append("apikey",userCredentials.apikey );
  let formdata = new FormData();
  formdata.append("feedback", feedback);
  formdata.append("rating", rating);
  formdata.append("app", app);
  formdata.append("version", version);
  var requestOptions = {
    method: 'POST',
    body: formdata,
    headers: myHeaders,
    redirect: 'follow'
  };
  fetch( rateus , requestOptions)
    .then(response => response.json())
    .then(async (result) => {
      if(result.r == 1){
        message.success('Thank you');
        localStorage.setItem('rating', JSON.stringify(rating));
        localStorage.setItem('feedback', JSON.stringify(feedback));
        setisloading(false)

      }else{
        message.error('Something went wrong try again ');
        setisloading(false)
      }
    })
    .catch( (error) =>{ 
      message.error('Something went wrong try again ');
      setisloading(false)
     });


}

  return (
    <div className="ratecard">
    <div className="userImg"><img src={rateimg} alt="userimage" /></div>
    <div className="userName">Enjoy Our App</div>
      <div className="userEmail">if you enjoy our app, please rate us </div>
      <Rate allowHalf onChange={handleChange}  value={rating} />
      <TextArea onChange={onChange} value={feedback} placeholder="Feedack(optional)" showCount maxLength={100} style={{marginTop:'20px'}} rows={3} />
      <div className="logout"><Button className="loginBtn" onClick={()=>{
        if(rating <= 0){
          message.error('Please Give your Rating');

        }else{
          setisloading(true)
          rateUs()
        }
        }}  type="primary"><span className="btntext">{isloading?<LoadingOutlined /> :"RATE NOW"}</span></Button></div>

  </div>);
}
export default RateCard;