import React from 'react'
import './styles.css';
import { useHistory } from 'react-router-dom'

const SubjectCard=(props)=> {
    const history = useHistory();
    const[emptyData,setEmptyData]=React.useState([{},{},{},{},{},{},{},{}])

    if (props.data.length === 0 && !props.isLoading) {
        return null
    }

    if(props.isLoading && props.data.length === 0){
        return(
            <div className="subject_continer">
            <div className="list_titel">
               Explore Categories
            </div>
           <div className="subject_body">
                 {emptyData.map((item)=>{
                     return(
                    <div className="subject_item">
                          <div  className="listitem" style={{width:"70px",height:"40px"}}></div>
                    </div>
                     )
                 })}
            </div>
           
        </div>
        )
    }

   return (
        <div className="subject_continer">
            <div className="list_titel">
               Explore Categories
            </div>
          <div className="subject_body">
                 {props.data.map((item)=>{
                     return(
                    <div className="subject_item">
                          <div onClick={()=>{history.push({pathname:"/bookbysubject/"+item.uniqueName,state:{name:item.name}})}} className="listitem">{item.name}</div>
                    </div>
                     )
                 })}
            </div>
           
        </div>
    )
                
}
export default SubjectCard;