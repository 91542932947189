const reactReaderStyles = {
  container: {
    overflow: "hidden",
    height: "100%",
   
  },
  readerArea: {
    position: "relative",
    zIndex: 1,
    height: "100%",
    width: "100%",
    backgroundColor: "#fff",
    transition: "all .3s ease"
    
  },
  containerExpanded: {
    transform: "translateX(256px)"
  },
  titleArea: {
    position: "absolute",
    top: 15,
    left: 45,
    right: 90,
    fontSize:"15px",
    textAlign: "center",
    fontWeight:'bolder',
    // color: "rgb(52 52 52)",
    whiteSpace: "nowrap",
    overflow: "hidden",
    letterSpacing:'1px',
    textOverflow: "ellipsis",
  },
  rightIcons: {
    position: "absolute",
    top: 15,
    left: 50,
    fontSize:"19px",
    right: "1.5rem",
    textAlign: "right",
    color: "rgb(52 52 52)"
  },
  reader: {
    position: "absolute",
    top: 50,
    left: 9,
    bottom: 10,
    right: 9,
    zIndex: 0,
  },
 
  swipeWrapper: {
    position: "absolute",
    top: 0,
    left: 10,
    bottom: 20,
    right: 10,
    zIndex: 1,
  },
  prev: {
    left: 1,
  },
  next: {
    right: 1
  },
  arrow: {
    outline: "none",
    border: "none",
    background: "none",
    position: "absolute",
    top: "50%",
    marginTop: -32,
    fontSize: 20,
    padding: "0 0px",
    color: "#000000",
    fontFamily: "arial, sans-serif",
    cursor: "pointer",
    userSelect: "none",
    appearance: "none",
    fontWeight: "normal"
  },
  arrowHover: {
    color: "#777"
  },
  tocBackground: {
    position: "absolute",
    left: 256,
    top: 0,
    bottom: 0,
    right: 0,
    zIndex: 1
  },
  tocArea: {
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    zIndex: 0,
    width: 256,
    overflowY: "auto",
    WebkitOverflowScrolling: "touch",
    background: '#fff',
    padding: "10px 0"
  },
  tocAreaButton: {
    userSelect: "none",
    appearance: "none",
    background: "none",
    border: "none",
    display: "block",
    fontFamily: "sans-serif",
    width: "100%",
    fontSize: ".9em",
    textAlign: "left",
    padding: ".7em 1em",
    // borderBottom: "0.1px solid rgb(249 249 249)",
    color: "rgb(52 52 52)",
    boxSizing: "border-box",
    outline: "none",
    cursor: "pointer"
  },
  tocButton: {
    background: "none",
    border: "none",
    width: 32,
    height: 32,
    position: "absolute",
    top: 10,
    left: 10,
    borderRadius: 2,
    outline: "none",
    cursor: "pointer"
  },
  tocButtonExpanded: {
    background: "#f2f2f2"
  },
  tocButtonBar: {
    position: "absolute",
    width: "60%",
    background: "rgb(52 52 52)",
    height: 2,
    left: "50%",
    margin: "-1px -30%",
    top: "50%",
    transition: "all .5s ease"
  },
  tocButtonBarTop: {
    top: "35%"
  },
  tocButtonBottom: {
    top: "66%"
  },
  loadingView: {
    position: "absolute",
    top: "50%",
    left: "10%",
    right: "10%",
    color: "#ccc",
    textAlign: "center",
    margintop: "-.5em"
  }
};

export default reactReaderStyles
