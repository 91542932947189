import React, {useState,useEffect} from "react";
import { Row, Col,  } from 'antd';
import {bookcover,subject} from '../../api/api'
import { useHistory,useLocation} from 'react-router-dom'
import './styles.css';
import Header from '../../component/pageheader/pageHeader';
import listbook from '../../api/images/emptybook.png'
const Booksbysubject=(props)=> {
    const history = useHistory();
    const location = useLocation();
    const name = location.state.name

    const [books,setBooks]=useState([])
    const [emptyBooks,setEmptyBooks]=useState([{},{},{},{},{},{},{},{}])
    const subjectid=props.match.params.subject
    const [isLoading,setIsLoading]=useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)

        setIsLoading(true)
         getBooksList()
     }, [])
   
   
  const  getBooksList = async() => {
         var requestOptions = {
          method: 'GET',
          redirect: 'follow'
        };
        
        fetch(subject+subjectid, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setBooks(result.data)
            setIsLoading(false)
          })
          .catch(error => console.log('error', error));
    }
   
   
  
  if(isLoading){
    return (
        <div className="continer">
        <Header titel={name} />
        <Row className="listcontiner">
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>
            <Row>
                {emptyBooks.map(item =>(
                    <Col className="journalList"  xs={12} sm={8} md={6} lg={4} xl={4}>
                        <div  className="journalCover" style={{  backgroundColor:"#EEFBF9" }}>
                         </div>
                    </Col>
                ))}
            </Row>
           </Col>
        </Row>
        </div>
    );
  }

   
        return (
            <div className="continer">
            <Header titel={name} />

          {books.length >0 ?  <Row className="listcontiner">
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>

                 <Row>
                    {books.map(item =>(
                        <Col className="journalList"  xs={12} sm={8} md={6} lg={4} xl={4}>
                            <div onClick={()=>{history.push({pathname:'/book/isbn/'+item.isbn,state:{contentid:item.contentId}})}}  className="journalCover" style={{ backgroundImage: `url(${bookcover+item.contentId+"/cover.png"})`, backgroundColor:"#EEFBF9" }}>
                              
                            </div>
                        </Col>
                    ))}
                </Row>
               
            </Col>
            </Row>:
             <div className="nodata">
                 <img src={listbook} width={"100px"}/>
                 No book found under the {subjectid}  category,Sorry!
            </div>
            }
            </div>
        );
    }


export default Booksbysubject;