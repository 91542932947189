import Logo from './images/mybareacts.png';
export const SERVER_URL = "https://www.mybareacts.com/";
export const APP_NAME="Mybareacts";
export const LOGO=Logo;
export const FbLoginID=1669916393342748;
export const AppleClientId="co.novareader.researchpad"
export const GmailClientId="458582913994-ufc1of4nlhc3vmc9h89rdhupeon86bss.apps.googleusercontent.com"
export const OrcidClientId="https://orcid.org/oauth/authorize?client_id=APP-NE6GOPUF09930F9B&response_type=code&scope=/authenticate&redirect_uri=https://mobile.mybareacts.com/login"
export const URL="https://mobile.mybareacts.com/"
export const AppVersion="0.3.0"
export const RedirectUri="https://www.mybareacts.com/api/applelogin"

