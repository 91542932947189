import React, { useState, useEffect } from 'react';
import Header from '../../component/dashboard/header';
import './styles.css';
import Subscription from './subscription';
import Favorite from './Favorite';
import Downloaded from './downloaded';
import 'antd-mobile/dist/antd-mobile.css';
import { Row, Col,} from 'antd';
import { useHistory } from 'react-router-dom'
import { useDispatch,useSelector } from 'react-redux';
import { settab } from '../../redux/actions/dashboardActions';
const Library = () => {
    const history = useHistory();
    const [subscription, setsubscription] = useState(true);
    const [favorite, setfavorite] = useState(false);
    const [downloaded, setdownloaded] = useState(false);
    const [loading, setloading] = useState(true);
    const dispatch = useDispatch();
    const state = useSelector((state) => state.App);
     
    useEffect(() => {
        window.scrollTo(0, 0)
        const userCredentials = JSON.parse(localStorage.getItem('userCredentials'));
        if (userCredentials != null) {
            if (userCredentials.emailVerified != 1) {
                history.push('./login');
                setloading(false)
            }
        }
        else {
            history.push('./login');
            setloading(false)
        }
    }, [])

    const setSub = () => {
        dispatch(settab("sub"))

       
    }
    const setfav = () => {
        dispatch(settab("fav"))

       
    }
    const setdow = () => {
        dispatch(settab("dow"))

       
    }
    
   
    return (
        <div style={{backgroundColor:'#fff'}}>
            <Row style={{ "padding": "0px", "margin": "0px 0px 0px 0px" }}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>
                    {/* <Header/> */}
                    <div className="tabbar">
                        <div className="tabbar_item" onClick={() => setSub()} style={{ borderBottom:state.tab=="sub" ? "3px  solid #33A3F4" : "0px solid #CFD3D2", color: state.tab=="sub" ? "#33A3F4" : "#CFD3D2" }}>SUBSCRIBED</div>
                        <div className="tabbar_item" onClick={() => setfav()} style={{ borderBottom: state.tab=="fav" ? "3px solid #33A3F4" : "0px solid #CFD3D2", color: state.tab=="fav" ? "#33A3F4" : "#CFD3D2" }}>FAVORITE</div>
                        {/* <div className="tabbar_item" onClick={() => setdow()} style={{ borderBottom: state.tab=="dow" ? "3px solid #33A3F4" : "0px solid #CFD3D2", color: state.tab=="dow" ? "#33A3F4" : "#CFD3D2" }}> DOWNLOADED</div> */}
                    </div>
                    {state.tab=="sub" ?
                        <Subscription setdownloade={()=>setdow()} />
                        : null}
                    {state.tab=="fav" ?
                        <Favorite setsub={()=>setSub()} />
                        : null}
                    {/* {state.tab=="dow" ?
                        <Downloaded setsub={()=>setSub()} />
                        : null} */}
                </Col>
            </Row>
        </div>

    )
}

export default Library;