import React, { useState } from "react";
import { Row, Col ,message} from 'antd';
import { bookcover, getbook,search } from '../../api/api'
import { useHistory } from 'react-router-dom'
import { LeftOutlined, LoadingOutlined, SearchOutlined, CloseOutlined } from '@ant-design/icons';
import { updateSeachbooks } from '../../redux/actions/dashboardActions';
import { useSelector, useDispatch } from 'react-redux';
import './styles.css';

const Searchscreen = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [isloading, setisloading] = useState(false)
    const [ismoreloading, setismoreloading] = useState(false)
    const [ismoredata, setismoredata] = useState(true)
    const searchbooksvalues = useSelector((state) => state.App.searchbooksvalues);
    const [text, settext] = useState(searchbooksvalues.searchtext)

    const getBooksList = async () => {
        let url = search;
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(url+"cnt=1&q="+searchbooksvalues.searchtext+"&sort=recentold", requestOptions)
            .then(response => response.json())
            .then(result => {
                dispatch(updateSeachbooks(searchbooksvalues.books.concat(result.data), searchbooksvalues.pagination + 20, text))
                if (result.data.length == 0) {
                    setismoredata(false)
                    message.warning('No Data Found ');
                }
                setisloading(false)
                setismoreloading(false)

            })
            .catch(error => {
                setisloading(false)
                setismoreloading(false)
            });
    }

    const onSearch = () => {
        if (text) {
            dispatch(updateSeachbooks([], 0, text))
            setismoredata(true)
            setisloading(true)
            getBooksList()
        }
    }

    const onClear = () => {
        settext("")
        dispatch(updateSeachbooks([], 0, ""))

    }

    const loademore = () => {
        setismoreloading(true)
        getBooksList()
    }

    const updatetext = event => {
        // if (event.target.value.length == 0) {
        //     settext("")
        //     dispatch(updateSeachbooks([], 0, ""))
        // }
        console.log(event.target.value.length)
        settext(event.target.value)

    }



    return (
        <div className="searchscreen" >
            <div className="searchheader">
                <div onClick={() => { history.goBack() }} className="gobackicon">
                    <LeftOutlined />
                </div>
                <div className="searchinput">
                    <div className='searchbox'>
                        <input type="search" className="searchboxfiled" placeholder="Search by Title,Author,Book ..." name="search" value={text} onChange={updatetext} />
                      {text &&  <CloseOutlined onClick={onClear} className='clearicon' />}
                        {isloading ? <div className='searchicon'> <LoadingOutlined onClick={onSearch} /></div> : <SearchOutlined onClick={onSearch} className='searchicon' />}
                    </div>
                </div>
            </div>
            {searchbooksvalues.books.length > 0 ? <Row className="booklist">
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 1 }}>
                    <Row>
                        {searchbooksvalues.books.map(item => (
                            <Col className="journalList" xs={12} sm={8} md={6} lg={4} xl={4}>
                                <div onClick={() => { history.push({ pathname: '/book/isbn/' + item.isbn, state: { contentid: item.contentId } }) }} className="journalCover" style={{ backgroundImage: `url(${bookcover + item.contentId + "/cover.png"})`, backgroundColor: "#EEFBF9" }}>
                                </div>
                            </Col>
                        ))}
                        {/* {ismoredata ? <div className="moreloading">
                            {ismoreloading ? <div className="moreloading"> <LoadingOutlined /> <span>Loading...</span></div> : <div className="moreloading" onClick={loademore}> <span>Load More</span></div>}
                        </div> : null} */}
                    </Row>

                </Col>
            </Row> :
                <div className="nodata">
                    Search by Title,Author,ISBN,Series,Collection,Publisher or subject
                </div>}
        </div>
    );
}


export default Searchscreen;