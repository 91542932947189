import React, {useState,useEffect} from "react";
import { Row, Col,Spin  } from 'antd';
import {bookcover,getJournalinfo,serverurl} from '../../api/api'
import { useHistory } from 'react-router-dom'
import './styles.css';
import Header from '../../component/pageheader/pageHeader';

const Journal=(props)=> {
    const history = useHistory();
    const [journal,setjournal]=useState([])
    const [loading,setloading]=useState(true)
    const category=props.match.params.category
    const id=props.match.params.id
    let start=0
    useEffect(() => {
        getJournalList()
     }, [])
     useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
      }, []);
    
      function handleScroll() {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset;
        if (windowBottom >= docHeight) {
            getJournalList()
        } else {
                // alert('not at bottom');
         
        }
      }
   
   
  const  getJournalList = async() => {
      let url=getJournalinfo+id+"?"+"start="+start;
     
         var requestOptions = {
          method: 'GET',
          redirect: 'follow'
        };
        
        fetch(url, requestOptions)
          .then(response => response.json())
          .then(result =>{
             setjournal(prevTime=>prevTime.concat(result.article))
             setloading(false)
               start=start+20
          })
          .catch(error => console.log('error', error));
    }

    const getMathVal=()=>{
        const mathval = Math.floor(Math.random() * 10);
        const myArray = ['url('+serverurl+'public/img/joucover/1.png)',
        'url('+serverurl+'public/img/joucover/2.png)',
        'url('+serverurl+'public/img/joucover/3.png)',
        'url('+serverurl+'public/img/joucover/4.png)',
        'url('+serverurl+'public/img/joucover/5.png)',
        'url('+serverurl+'public/img/joucover/6.png)',
        'url('+serverurl+'public/img/joucover/7.png)',
        'url('+serverurl+'public/img/joucover/8.png)',
        'url('+serverurl+'public/img/joucover/9.png)',
       ];
        const returnVal = myArray[mathval]
        return returnVal;
    }

    const readfunction=(item)=>{
        if(item.access==1){
               if(item.pdfExists==true){
                history.push('/pdf/' + item.contentId + "/" + "000" + "/" + item.name + "/" + item.elasticId + "/" + "yes"+"/"+"article")

               }else{
                history.push('/reader/' + item.contentId + "/" + "000" + "/" + item.name + "/" + item.elasticId + "/" + "yes"+"/"+"article")

               }
        }
    }

   
   
   
    if (loading) {
        return (
            <div style={{ height:'100vh',backgroundColor:'#ffffff' }}>
                   <Header titel="Article List"/>
                   <div style={{height:'90vh',alignItems:'center',justifyContent:'center',display:'flex'}}>
                   <Spin />
                   </div>
                  
                  
            </div>
        )
    }

   
        return (
            <div style={{height:'100vh',backgroundColor:'#ffffff'}}>
            <Header titel="Article List" />

            <Row style={{ "padding": "0px", "margin": "0px 0px 0px 0px", backgroundColor: '#ffffff' }}>
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>
              <div style={{marginLeft:'13px'}}>{category}</div>
                 <Row>
                    {journal.map(item =>(
                        <Col className="journalList"  xs={12} sm={8} md={6} lg={4} xl={4}>
                            <div onClick={()=>{readfunction(item)}}   className="journalCover" style={{ backgroundImage:getMathVal(), backgroundColor:"#EEFBF9" }}>
                                <div className="pubName">{item.name }</div>
                                    {/* <div className="artName">{ item.journalname }</div> */}
                            </div>
                        </Col>
                    ))}
                </Row>
               
            </Col>
            </Row>
            </div>
        );
    }


export default Journal;