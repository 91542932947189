import Logo from './images/nova.png';
export const SERVER_URL = "https://vijaynicole.novareader.co/";
export const APP_NAME="Vijaynicole";
export const LOGO=Logo;
export const APPID=388256706125525;
export const URL="http://mobilevijaynicole.novareader.co/"





