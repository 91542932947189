import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { pdfURL, bookcover,articlepdfURL } from '../../api/api';
import PDFViewer from '../../component/PDFViewer/PDFViewer';
import PDFJSBackend from '../../backends/pdfjs';
import Header from '../../component/pageheader/pageHeader';
import { Spin, Col, Row} from 'antd';
import { useIndexedDB } from 'react-indexed-db';

const Pdf = (props) => { 
  const contentid=props.match.params.contentid
  const isbn=props.match.params.isbn
  const titel=props.match.params.title
  const elasticid=props.match.params.elasticid
  const isonline=props.match.params.isonline
  const type=props.match.params.type

  const { add, getByIndex } = useIndexedDB('epub');
  const [location, setlocation] = useState('')
  const [loading, setloading] = useState(false)
  useEffect(() => {
    setloading(true)
    let name=titel;
    if(isonline=="yes"){
      name=titel+ "-pdf"
    }else{
      name=titel
    }
    getByIndex('name', name).then(file => {
      if (file) {
        const url = window.URL.createObjectURL(new Blob([file.file]));
        localStorage.setItem('pdfurl', url);
        setlocation(url)
        setloading(false)
      } else {
        const userCredentials = JSON.parse(localStorage.getItem('userCredentials'));
        getdata(userCredentials.apikey);
      }
    });


  }, [])
  const getdata = (apikey) => {
    setloading(true)
    let url=pdfURL;
    if(type=="article"){
      url=articlepdfURL
    }
    var myHeaders = new Headers();
    myHeaders.append("apikey", apikey);
    myHeaders.append("Content-Type", "appl");
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };
    fetch(url + elasticid, requestOptions)
      .then(response => response.blob())
      .then(file => {
        fetch(bookcover + contentid + "/cover.png")
          .then(res => res.blob())
          .then(res => {
            console.log("pdf",file)
            add({ name: titel + "-pdf", file: file, img: res, type: "pdf" }).then(
              event => {
                const url = URL.createObjectURL(new Blob([file]))
                localStorage.setItem('pdfurl', url);
                setlocation(url)
                setloading(false)
              },
              error => {
                setloading(false)
             }
            );
          })

      })

      .catch(error => console.log('error', error));
  }

if (loading) {
    return (
      <div>
              <Header />
              <Row>
                <Col style={{ height: '95vh', width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
                  <p style={{ letterSpacing: '2px', marginTop: '10px' }}>{titel}</p>
                  <img src={bookcover + contentid + "/cover.png"} style={{ height: '80%', width: '100%', backgroundColor: '#cccccc' }} alt="" loading="lazy" />
                  <div style={{ height: '20%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', backgroundColor: "#ffffff" }}>
                    <Spin />
                    <p style={{ color: '#CFD3D2', letterSpacing: '2px', marginTop: '10px', marginLeft: '10px' }}>Downloading...</p>
                  </div>
                </Col>
              </Row>
            </div>
    )
  }
  return (
    <PDFViewer
      backend={PDFJSBackend}
      src={location}
    />
  )
}

export default Pdf;