import React, {useState,useEffect} from "react";
import { Row, Col,  } from 'antd';
import {bookcover,getbook} from '../../api/api'
import { useHistory } from 'react-router-dom'
import Header from '../../component/pageheader/pageHeader';
import {LoadingOutlined} from '@ant-design/icons';
import './styles.css';

const Booksbycategory=(props)=> {
    const history = useHistory();
    const [category,setcategory]= useState(props.match.params.category)
    const [books,setBooks]=useState([])
    const [emptyBooks,setEmptyBooks]=useState([{},{},{},{},{},{},{},{}])
    const [isLoading,setIsLoading]=useState(false)
    const [isMoreLoading,setIsMoreLoading]=useState(false)
    const [isHaveMoreData,setIsHaveMoreData]=useState(true)
    const [pagenation,setPagenation]=useState(0)

   
   
    useEffect(() => {
        window.scrollTo(0, 0)
        setIsLoading(true)
        getBooksList()
     }, [])
    
   
const  getBooksList = async() => {
    console.log("pagenation",pagenation)
      let url=getbook+pagenation;
      console.log("cat",category)
      if(category=="newreleases"){
        setcategory("New Releases")
          url=getbook+pagenation
      }else if(category=="booksbyrating"){
        setcategory("Books By Rating")
          url=getbook+pagenation+"&rating=1"
      }else if(category=="openaccessbooks"){
        setcategory("Open Access Books")
         url=getbook+pagenation+"&access=1"
      }else if(category=="paidbook"){
        setcategory("Paid Book")
        url=getbook+pagenation+"&paid=1"
     }
         var requestOptions = {
          method: 'GET',
          redirect: 'follow'
        };
        
        fetch(url, requestOptions)
          .then(response => response.json())
          .then(result =>{
               setBooks(prevTime=>prevTime.concat(result.data))
               setIsLoading(false)
               setIsMoreLoading(false)
               setPagenation(pagenation+20)
               if(result.data.length < 20){
                setIsHaveMoreData(false)

               }
          })
          .catch(error =>{ 
            setIsLoading(false)
            setIsMoreLoading(false)
           });
    }

    const loademore =() =>{
        setIsMoreLoading(true)
        getBooksList()
    } 
   
   
  
  if(isLoading){
    return (
        <div className="continer">
        <Header titel={category} />
        <Row className="listcontiner">
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>
            <Row>
                {emptyBooks.map(item =>(
                    <Col className="journalList"  xs={12} sm={8} md={6} lg={4} xl={4}>
                        <div  className="journalCover" style={{  backgroundColor:"#EEFBF9" }}>
                         </div>
                    </Col>
                ))}
            </Row>
           </Col>
        </Row>
        </div>
    );
  }
   
        return (
            <div className="continer">
            <Header titel={category} />
          {books.length >0 ?  <Row className="listcontiner">
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>
                <Row>
                    {books.map(item =>(
                        <Col className="journalList"  xs={12} sm={8} md={6} lg={4} xl={4}>
                             <div onClick={()=>{ history.push({pathname:'/book/isbn/'+ item.eisbn,state:{contentid:item.contentId}})}}  className="journalCover" style={{ backgroundImage: `url(${bookcover+item.contentId+"/cover.png"})`, backgroundColor:"#EEFBF9" }}>
                             </div>
                        </Col>
                    ))}
                     {isHaveMoreData ?<div className="moreloading">
                    {isMoreLoading?<div className="moreloading"> <LoadingOutlined/> <span>Loading...</span></div>:<div className="moreloading" onClick={loademore}> <span>Load More</span></div>}
                    </div>:null}
                </Row>
               </Col>
             </Row>:
             <div className="nodata">
                  Empty
             </div>
             }
            </div>
        );
    }


export default Booksbycategory;