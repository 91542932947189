import React, { useState, useEffect,useRef } from "react";
import { connect } from "react-redux"
import { ReactReader } from "../modules";
import 'antd/dist/antd.css';
import Epub from "epubjs/lib/index";
import { SettingOutlined,CloseOutlined } from '@ant-design/icons';
import DataStore from '../datastore/Utility';
import { epubURL, bookcover,articleepubURL } from '../api/api'
import Header from '../component/pageheader/pageHeader';
import { Spin, Col, Row, Drawer, Switch, Select, Slider ,Space} from 'antd';
import { fontaction,lineaction,nightmodeaction ,fontfamilyaction} from '../redux/actions/dashboardActions';
import { Container, ReaderContainer, FontSizeButton, } from "./Components";
import { useIndexedDB } from 'react-indexed-db';
import {useLocation } from 'react-router-dom'

const { Option } = Select;
// global.ePub = Epub;
const Reader = ({myfontactin,mylineaction,mynightmodeaction,myfontfamilyaction,epubstyle,selecteditem,istocopen,...otherProps}) => {
  const nlocation = useLocation();
  const contentid= nlocation.state.contentid
  const isbn=nlocation.state.isbn
  const titel=nlocation.state.name
  const elasticid=otherProps.match.params.elasticid
  const type=nlocation.state.type
  const [page, setPage] = useState('')
  const renditionRef = useRef(null)
  const tocRef = useRef(null)
  const { add , getByIndex} = useIndexedDB('epub');
  const [loading, setloading] = useState(true)
  const [location, setlocation] = useState(0)
  const [localFile, setlocalFile] = useState("")
  const [localName, setlocalName] = useState("")
  const [rendition, setrendition] = useState(null)
  const [visible, setVisible] = useState(false);
  const [fontProps,setfontProps]=useState([])
  useEffect(() => {
    getByIndex('name',titel).then(file => {
     if(file){
        localStorage.setItem('currentbook', titel);
        const url = URL.createObjectURL(new Blob([file]))
        localStorage.setItem('epuburl', url);
        let locaion =JSON.parse (localStorage.getItem(titel));
        if(locaion){
          setlocation(locaion.start)
      }
        setlocalName(titel)
        setlocalFile(file.file);
        setloading(false);
     }else{
    const userCredentials = JSON.parse(localStorage.getItem('userCredentials'));
    getdata(userCredentials.apikey);
     }
    });
   
  }, [])
  const savefile = ({name,file}) => {
    add({ name: name, file: file }).then(
      event => {
        console.log('ID Generated: ', event.target.result);
      },
      error => {
        console.log(error);
      }
    );
  };

  const getdata = (apikey) => {
    setlocalName(titel)
    let url=epubURL;
    if(type=="article"){
      url=articleepubURL
    }
    var myHeaders = new Headers();
    myHeaders.append("apikey", apikey);
    myHeaders.append("Content-Type", "appl");
    var requestOptions = {
      method: 'GET',
      responseType: 'blob',
      headers: myHeaders,
    };
     
    fetch(url + elasticid + ".epub", requestOptions)
      .then (response => response.blob())
      .then(async (result) => {
        console.log("epub",result)
        const url = URL.createObjectURL(new Blob([result]))
        localStorage.setItem('epuburl', url);
        if ('arrayBuffer' in result) return await result.arrayBuffer();
        return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject;
        reader.readAsArrayBuffer(result);
    });
     }).then(file=>{
          fetch(bookcover + contentid + "/cover.png")
          .then(res=> res.blob())
          .then(res=>{
              add({ name:titel,file:file,img:res,type:"epub" }).then(
              event => {
                localStorage.setItem('currentbook', titel);
                let locaion =JSON.parse (localStorage.getItem(titel));
                console.log("last locatin",locaion)
                if(locaion){
                  setlocation(locaion.start)
               }      
                        
                setlocalFile(file);
                setloading(false)
              },
              error => {
                console.log(error);
                setlocalFile(file);
                setloading(false)
              }
            );
           })
            
      }) 
     .catch(error => console.log('error', error));
  }

  const onLocationChanged = location => {
    console.log(location)
    setlocation(location)
   
  };
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

const fontSizeChange = (value) => {
     myfontactin(value)
     styleSetting()
}

  const lineHeightChange = (value) => {
    mylineaction(value)
    styleSetting()

  }
  const nightModeChange = (value) => {
    if(value){
      mynightmodeaction("#ffffff","#000000","100%")
      styleSetting()
    }else{
      mynightmodeaction("#000000","#ffffff","0%")
      styleSetting()
    }
    onClose();

  }
  function onFontChange(value) {
    myfontfamilyaction(value)
    console.log(value)
    styleSetting()
  }

  
  const Settingdrawer = () => {
    return (
      <div style={{ marginLeft: '5px', marginRight: '5px', }}>
         <div style={{ width: '100%', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <p style={{ fontWeight: '500',marginTop:'14px',fontSize:"20px",marginLeft:"10px" }}>Setting</p>
          {/* <CloseOutlined  style={{color:epubstyle.color}} onClick={onClose}/> */}
        </div>
        <div style={{ width: '100%', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <p style={{ fontWeight: '500',marginTop:'14px' }}>Night Mode</p>
          <Switch checked={epubstyle.bgcolor=="#ffffff"?false:true} onChange={nightModeChange} />
        </div>
        

        <div style={{ width: '100%', height: '70px', display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
          <p style={{ fontWeight: '500' }}>Font Size</p>
          <Slider
            defaultValue={epubstyle.fontsize}
            min={1}
            max={20}
            onChange={fontSizeChange}
          />
        </div>
        <div style={{ width: '100%', height: '70px', display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
          <p style={{ fontWeight: '500' }}>Line Height</p>
          <Slider
            defaultValue={epubstyle.lineheight}
            min={1}
            max={20}
            onChange={lineHeightChange}

          />
        </div>
      </div>
    )
  }
  const styleSetting = () => {
    rendition.themes.register("defult", {
     
     "body": {
        "padding-top": "0px !important",
        "padding-bottom": "0px !important",
        "margin": "0 !important",
        "background":epubstyle.bgcolor,
        "color": epubstyle.color,
        "line-height": epubstyle.lineheight,
        "text-align":"justify",
        // "font-family":`${epubstyle.fontfamily} !important`,
        "font-size": `${epubstyle.fontsize}px !important`,
      },
      "p": {
        // "background":epubstyle.bgcolor,
        "color": epubstyle.color,
        "line-height": epubstyle.lineheight,
        "font-size": `${epubstyle.fontsize}px !important`,
        // "font-family":`${epubstyle.fontfamily} !important`,
        "text-align":"justify",
      },
      "table": {
        "margin-left": "auto",
        "margin-right": "auto",
        "display": "block",
        "overflow-x": "auto",
        "border": "0px solid #ddd",
      },
      "a": {
        "text-align":"justify",
         "line-height": epubstyle.lineheight,
        "color": "#1C92E8",
        "text-decoration": "none !important",
        "-webkit-text-fill-color": "inherit !important"
      },
      "img": {
        "filter": `grayscale(${epubstyle.filter})`,
        "background":epubstyle.bgcolor,
        // "display": "block",
        // "margin-left": "0",
        // "margin-right": "0",
       
      },
     
    });
    rendition.themes.select("defult");
  }


  const getRendition = rendition => {
    setrendition(rendition);
      rendition.flow("paginated");
  //    rendition.getContents().forEach(c => {
  //     [
  //       'https://fonts.googleapis.com/css?family=Arbutus+Slab',
  //       'https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i',
  //       'https://fonts.googleapis.com/css?family=Spectral',
  //       'https://fonts.googleapis.com/css?family=Libre+Baskerville',
  //       'https://fonts.googleapis.com/css?family=Merriweather'
  //     ].forEach(url => {
  //         let el = c.document.body.appendChild(c.document.createElement("link"));
  //         el.setAttribute("rel", "stylesheet");
  //         el.setAttribute("href", url);
  //     });
  // });
      //  rendition.spread("none");
    // console.log("style",epubstyle)
    rendition.themes.register("defult", {
      "body": {
        "padding-top": "0px !important",
        "padding-bottom": "0px !important",
        "margin": "0 !important",
        "background":epubstyle.bgcolor,
        "color": epubstyle.color,
        "line-height": epubstyle.lineheight,
        "font-size": `${epubstyle.fontsize}px !important`,
        // "font-family":`Merriweather !important`,
        "text-align":"justify",
      },
      "p": {
        // "background":epubstyle.bgcolor,
        "color": epubstyle.color,
        "line-height": epubstyle.lineheight,
        "font-size": `${epubstyle.fontsize}px !important`,
        "text-align":"justify",
        // "font-family":`Merriweather !important`,
        "letter-spacing":"1px"
      },
      "table": {
        "background":epubstyle.bgcolor,
        "margin-left": "auto",
        "margin-right": "auto",
        "display": "block",
        "overflow-x": "auto",
        "border": "0px solid #ddd",
      },
      "a": {
        "line-height": epubstyle.lineheight,
        "font-size": `${epubstyle.fontsize}px !important`,
        "text-align":"justify",
        "color": "#1C92E8",
        "text-decoration": "none !important",
        "-webkit-text-fill-color": "inherit !important",
      },
      "img": {
        "filter": `grayscale(${epubstyle.filter})`,
        "background":epubstyle.bgcolor,
       
      },
     
    });
    rendition.themes.select("defult");
  };

  const dataStore = new DataStore();
  if (loading) {
    return (
     
            <div>
              <Header />
              <Row>
                <Col style={{ height: '95vh', width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
                  <p style={{ letterSpacing: '2px', marginTop: '10px' }}>{titel}</p>
                  <img src={bookcover + contentid + "/cover.png"} style={{ height: '80%', width: '100%', backgroundColor: '#cccccc' }} alt="" loading="lazy" />
                  <div style={{ height: '20%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', backgroundColor: "#ffffff" }}>
                    <Spin />
                    <p style={{ color: '#CFD3D2', letterSpacing: '2px', marginTop: '10px', marginLeft: '10px' }}>Downloading...</p>
                  </div>
                </Col>
              </Row>
            </div>
         
    )
  }
  return (
   
    <Container>
      <div >
        <ReaderContainer fullscreen={true}>
          <ReactReader dataStore={dataStore}
            url={localFile}
            title={localName}
            location={location}
            locationChanged={onLocationChanged}
            getRendition={getRendition}/>
          <FontSizeButton onClick={showDrawer}>
           {istocopen? null:<SettingOutlined style={{color:epubstyle.color,fontSize:"17px"}} />}
          </FontSizeButton>
        </ReaderContainer>
        <Drawer
          mask={false}
          placement="right"
          closable={true}
          closeIcon={<CloseOutlined style={{color:epubstyle.color}} />}
          onClose={onClose}
          drawerStyle={{color:epubstyle.color,backgroundColor:epubstyle.bgcolor}}
          visible={visible}>
          {Settingdrawer()}
        </Drawer>
      </div>
    </Container>
  );

}
const mapStateToProps = state => ({
  epubstyle: state.App.epubstyle,
  selecteditem: state.App.selecteditem,
  istocopen:state.App.istocopen
})

const mapDispatchToProps = {
  myfontactin:fontaction,
  mylineaction:lineaction,
  mynightmodeaction:nightmodeaction,
  myfontfamilyaction:fontfamilyaction,
}



export default connect(mapStateToProps,mapDispatchToProps)(Reader);
