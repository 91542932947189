import { ActionType } from '../contants/actions-type';
const initialState = {
    subject: [],
    newbooks: [{}, {}, {}, {}, {}],
    bestratingbooks: [{}, {}, {}, {}, {}],
    openaccessbooks: [{}, {}, {}, {}, {}],
    newjournals: [{}, {}, {}, {}, {}],
    bestratingjournals: [{}, {}, {}, {}, {}],
    istocopen: false,
    selecteditem: {},
    offlinedata: {},
    subscriptionbook: [],
    currentlocation: {
        chapter: " ",
        display: 0,
        total: 0
    },
    searchbooksvalues: {
        books: [],
        searchtext: "",
        pagination: 0
    },
    isfetchsubscription: true,
    tab: "sub",
    hometab: "home",
    epubstyle: {
        fontsize: 16,
        lineheight: 2,
        color: '#000000',
        fontfamily: 'Roboto',
        bgcolor: "#ffffff",
        filter: "0%"
    },
    readerArea: {
        position: "relative",
        zIndex: 1,
        height: "100%",
        width: "100%",
        backgroundColor: "#ffffff",
        transition: "all .3s ease"
    },
}



export const appReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionType.SET_SUBJECT:
            return { ...state, subject: payload }

        case ActionType.SET_NEWBOOKS:
            return { ...state, newbooks: payload }

        case ActionType.SET_BESTRATINGBOOKS:
            return { ...state, bestratingbooks: payload }

        case ActionType.SET_OPENACCESSGBOOKS:
            return { ...state, openaccessbooks: payload }

        case ActionType.SET_NEWJOURNALS:
            return { ...state, newjournals: payload }

        case ActionType.SET_BESTRATINGJOURNALS:
            return { ...state, bestratingjournals: payload }

        case ActionType.SET_SELECTEDITEM:
            return { ...state, selecteditem: payload }

        case ActionType.SET_OFFLINEDATA:
            return { ...state, offlinedata: payload }

        case ActionType.SET_SUBSCRIPTION:
            return { ...state, subscriptionbook: payload }

        case ActionType.SET_SUBSCRIPTIONFETCH:
            return { ...state, isfetchsubscription: payload }

        case ActionType.SET_THEME:

            return { ...state, epubstyle: payload }
            case ActionType.SET_EPUBLOCATION:

                return { ...state, currentlocation: payload }
        case ActionType.SET_ISTOCOPEN:

            return { ...state, istocopen: payload }
        case ActionType.SET_BG:

            return { ...state, readerArea: payload }
        case ActionType.SET_TAB:

            return { ...state, tab: payload }
        case ActionType.SET_HOMETAB:

            return { ...state, hometab: payload }
        case ActionType.SET_RESET:

            return { ...state, subscriptionbook: [], isfetchsubscription: true }
        case ActionType.SET_SEARCHBOOKS:
            return { ...state, searchbooksvalues: payload }


        default:
            return state;
    }
}