import React,{useState} from 'react'
import './styles.css';
import {logo} from '../../api/api';
import { Form, Input, Button, message, Modal} from 'antd';
import { passwordresetURL } from '../../api/api';
import { useHistory } from 'react-router-dom';

const ResetPassword =(props)=>{
    const history = useHistory();
    const [Loadings,setLoadings]= useState(false)
    const [btntext,setbtntext]= useState("Change Password")


const  onFinish = async (values) => {
    console.log(values)
   if(values.Password == values.cpassword){
       setLoadings(true)
       setbtntext("Loading")
       const query = new URLSearchParams(props.location.search);
       const userid = query.get('userid')
    var myHeaders = new Headers();
    var formdata = new FormData();
    formdata.append("userid", userid);
    formdata.append("password",  values.Password);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(passwordresetURL, requestOptions)
      .then(response => response.json())
      .then(result => {
         console.log(result)
         if(result.r==1){
         setLoadings(false)
         setbtntext("Change Password")
         Modal.success({
            title: 'Success',
            content: 'Your Password has been Changed.',
            okText: 'OK',
          });
        }else{
            setLoadings(false)
            setbtntext("Change Password")
            message.error("something went wrong please try again")

        }
        })
     .catch(error =>{ 
        setLoadings(false)
        setbtntext("Change Password")
        message.error("something went wrong please try again")
        console.log('error', error)});
    }else{
      message.error("The password and confirmation password do not match.")
    }
       
}
    const  onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
    return(
        <div className="login_continer" >
             <div className="loginPage">
             <div className="logImg">
              <img style={{ width: '140px', marginTop: '20px' }} src={logo} alt="" />
              </div>
              <div>
          <div className="loginHead">Change Password</div>
          <div style={{marginTop:'10px'}}>
      <Form  name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          //  label="Password"
          name="Password"
          rules={[{required: true, message: 'Please input your password!' }]}
        >
          <Input.Password placeholder='Password'/>
        </Form.Item>

        <Form.Item
          // label="Confirm Password"
          name="cpassword"
          rules={[{required: true, message: 'Please input your confirm password!' }]}
        >
         <Input.Password placeholder='Confirm Password'/>
        </Form.Item>

          <Form.Item style={{ "clear": "both",marginTop:'30px'}}>
          <Button className="loginBtn"  type="primary" htmlType="submit" block loading={Loadings} >
              <span className="btntext">{btntext}</span>
          </Button>
        </Form.Item>
      </Form>

    </div>
          <div >
            <div style={{textAlign:'center'}} >Back to login form? <span style={{color:'#FF812E'}} onClick={()=>{history.replace("./login")}} >Login</span></div>
          </div>
        </div> 
             </div>
           
      </div>
    )
}

export default ResetPassword;