import React, { useState,useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Spin } from 'antd';

import { createGlobalStyle } from "styled-components";
// import FileReaderInput from "react-file-reader-input";
import { ReactReader, EpubView } from "../modules";
import { LeftCircleFilled, ArrowLeftOutlined } from "@ant-design/icons";
import 'antd/dist/antd.css'; 
import Epub from "epubjs/lib/index";
import {FontSizeOutlined } from '@ant-design/icons';
import DataStore from '../datastore/Utility';


import {
  Container,
  ReaderContainer,
  Bar,
  LogoWrapper,
  Logo,
  GenericButton,
  CloseIcon,
  FontSizeButton,
  ButtonWrapper
} from "./Components";
 import { WatchDirectoryKind } from "typescript";
global.ePub = Epub; 

const storage = global.localStorage || null;

var rendition = null;

const GlobalStyle = createGlobalStyle`
  * {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    margin: 0;
    padding: 0;
    color: inherit;
    font-size: inherit;
    font-weight: 300;
    line-height: 1.4;
    word-break: break-word;
  }
  html {
    font-size: 62.5%;
  }
  body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    font-size: 1.8rem;
    background: #333;
    position: absolute;
    height: 100%;
    width: 100%;
    color: #fff;
  }
`;

const Reader=()=> {
  const offlineitem = useSelector((state) => state.dashboard.offlinedata);
  console.log(offlineitem)
  const [loading,setloading]=useState(true)
  const [fullscreen,setfullscreen]=useState(true)
  const [location,setlocation]=useState(storage && storage.getItem("epub-location") ? storage.getItem("epub-location") : 2)
  const [localFile,setlocalFile]=useState("")
  const [localName,setlocalName]=useState("")
  const [largeText,setlargeText]=useState(false)
useEffect(()=>{
  getdata();
},[])
const getdata = () => {
    try{
    setloading(true)
    setlocalName(offlineitem.name)
    var byteString = atob(offlineitem.file.split(',')[1]);
    var mimeString = offlineitem.file.split(',')[0].split(':')[1].split(';')[0]
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
   var blob = new Blob([ab], { type: mimeString });
    console.log("data", blob)
    var arrayBuffer;
            var fileReader = new FileReader();
            fileReader.onload = function(event) {
                arrayBuffer = event.target.result;
                setlocalFile(event.target.result) 
                setloading(false)
                console.log(event.target.result)               
            };
            fileReader.readAsArrayBuffer(blob);


        }catch(error){
            console.log(error)
        }
}

 
 

 const onLocationChanged = location => {
    setlocation(location)
    // this.setState(
    //   {
    //     location
    //   },
      
        storage && storage.setItem("epub-location", location);
      
  
  };

 const onToggleFontSize = () => {
    const nextState = !largeText;
    setlargeText(nextState)
    // this.setState(
    //   {
    //     largeText: nextState
    //   },
    //   () => {
      rendition.themes.fontSize(nextState ? "140%" : "100%");
      // }
    // );
  };

  const getRendition = rendition => {
    // Set inital font-size, and add a pointer to rendition for later updates
    rendition = rendition;
    rendition.themes.fontSize(largeText ? "140%" : "100%");
  };
  // handleChangeFile = (event, results) => {
  //   if (results.length > 0) {
  //     const [e, file] = results[0];
  //     if (file.type !== "application/epub+zip") {
  //       return alert("Unsupported type");
  //     }
  //     this.setState({
  //       localFile: e.target.result,
  //       localName: file.name,
  //       location: null
  //     });
  //   }
  // };
 const  backToHome=()=>{
  }
  



    const dataStore = new DataStore();
    
   if(loading){
     return(
        <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
        <Spin />
        <p style={{ color: '#CFD3D2',letterSpacing:'2px',marginTop:'10px' }}>Loading ...</p>
    </div>
  
     )
   }
    return (
      <Container>
        <div>
        <GlobalStyle />
        <Bar>
          <LogoWrapper href="https://github.com/gerhardsletten/react-reader">
            <Logo
              src="https://gerhardsletten.github.io/react-reader/files/react-reader.svg"
              alt="React-reader - powered by epubjs"
            />
          </LogoWrapper>
          <ButtonWrapper>
            {/* <FileReaderInput as="buffer" onChange={this.handleChangeFile}>
              <GenericButton>Upload local epub</GenericButton>
            </FileReaderInput> */}
            {/* <GenericButton onClick={this.toggleFullscreen}>
              Use full browser window
              <CloseIcon />
            </GenericButton> */}
          </ButtonWrapper>
        </Bar>
        <ReaderContainer fullscreen={fullscreen}>
          <ReactReader dataStore = {dataStore}
            url={localFile}
            title={localName}
            location={location}
            locationChanged={onLocationChanged}
            getRendition={getRendition}
           // selected={this.selectText}
          />
           {/* <div className="backButtonHome" onClick={this.backToHome}>
                <ArrowLeftOutlined /> Back
           </div> */}
          
          {/* <FontSizeButton onClick={onToggleFontSize}>
          <FontSizeOutlined />
          </FontSizeButton> */}
        </ReaderContainer></div>
       
      </Container>
    );
  
}

export default Reader;
