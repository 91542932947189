import Logo from './images/anthem.png';
export const SERVER_URL = "https://anthempress.novareader.co/";
export const APP_NAME="Anthem Press";
export const LOGO=Logo;
export const APPID=263236729005851;
export const AppleClientId="T7YY6HCRKZ.co.cloudmatters.nova"
export const URL="https://mobileanthempress.novareader.co/"






